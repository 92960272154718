<template>
  <div>
    <p v-if="type == 'variable'" class="text-xxs text-gray-500 leading-tight">
      {{ $t("starting_from") }}
    </p>
    <div class="inline-block align-middle" :class="{ 'text-xl': size == 'lg' }">
      <Amount class="font-bold pr-2" :value="value" />
      <Amount
        v-if="regular"
        :value="regular"
        class="text-gray-400 line-through text-xs pr-2"
      />
      <span v-if="discount" class="text-warning-500 text-xxs">
        {{ discount }}% {{ $t("off") }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Number, default: null },
    regular: { type: Number, default: null },
    type: {
      type: String,
      default: "simple",
    },
    size: {
      type: String,
      default: "md",
    },
  },
  computed: {
    discount() {
      const price = Number(this.value);
      const regularPrice = Number(this.regular);
      if (!price || price > regularPrice) {
        return null;
      }
      return parseInt(100 - (price * 100) / regularPrice);
    },
  },
};
</script>
